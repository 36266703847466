import React from "react";
import Classes from "./Aboutfont.module.css";

const aboutfont = () => {
  return (
    <div className={Classes.about_font}>
      <h2>About</h2>
      <span>About Me</span>
    </div>
  );
};
export default aboutfont;
