import React from "react";
import Auxillary from "../../../../../../hoc/Auxillary";

const contentone = () => {
  return (
    <Auxillary>
      <span>
        <i className="fas fa-palette"></i>
      </span>
      <h5>Web Design</h5>
      <p>
        Passionate web designer, Skilled in Adobe Creative Suit, Html , Css.
        Creates seamless UX and UI with creative but funtional designs Desighned
        10+ websites.
      </p>
    </Auxillary>
  );
};
export default contentone;
