import React from "react";
import Classes from "./Header.module.css";

const header = () => {
  return (
    <div className={Classes.header}>
      <h2>Services</h2>
      <span>Services</span>
    </div>
  );
};
export default header;
